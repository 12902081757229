<script>
export default {
    "food_drinks": "comida y bebidas",
    "shopping": "compras",
    "home": "casa",
    "transport": "transporte",
    "vehicles": "vehículos",
    "entertainment": "entretenimiento",
    "communication_pc": "comunicación pc",
    "financial_expenses": "gastos financieros",
    "income": "ingresos",
    "other": "otro",
    "bars_venues": "bares y locales",
    "restaurants_fast_food": "restaurantes y comida rápida",
    "groceries": "comestibles",
    "clothing_shoes": "ropa y zapatos",
    "pets": "mascotas",
    "beauty_wellness": "belleza y bienestar",
    "stationery_tools": "papelería y herramientas",
    "home_garden": "hogar y jardín",
    "electronics_accessories": "electrónica, accesorios",
    "pharmacy": "farmacia",
    "children": "niños",
    "jewelry_accessories": "joyas y accesorios",
    "gift_expenses": "gastos de regalo",
    "family_help": "ayuda familiar",
    "leisure": "ocio",
    "rent": "alquiler",
    "property_insurance": "seguro de propiedad",
    "condominium": "condominio",
    "energy_utilities": "energía y servicios públicos",
    "maintenance": "mantenimiento",
    "mortgage": "hipoteca",
    "taxi": "taxi",
    "public_transport": "transporte público",
    "travel": "viaje",
    "business_trips": "viajes de negocios",
    "vehicle_insurance": "seguro de vehículos",
    "fuel": "combustible",
    "vehicle_maintenance": "mantenimiento de vehículos",
    "rentals": "alquileres",
    "parking": "aparcamiento",
    "tolls": "peajes",
    "alcohol": "alcohol",
    "tobacco": "tabaco",
    "culture_sports_events": "cultura, eventos deportivos",
    "donations_charity": "donaciones, caridad",
    "events": "eventos",
    "wedding": "boda",
    "education_development": "educación, desarrollo",
    "hobbies": "hobbies",
    "books_audio_subscriptions": "libros, audio, suscripciones",
    "lotteries_gambling": "loterías y juegos de azar",
    "health": "salud",
    "sports_fitness": "deportes, fitness",
    "tv_streaming": "transmisión de TV",
    "holidays": "vacaciones",
    "hotels": "hoteles",
    "internet": "internet",
    "postal_services": "servicios postales",
    "software_apps_games": "software, aplicaciones y juegos",
    "phone": "teléfono",
    "insurance": "seguros",
    "consulting": "consultoría",
    "fines": "multas",
    "loans_interest": "préstamos e intereses",
    "taxes": "impuestos",
    "taxes_fees": "impuestos, tasas",
    "real_estate": "bienes raíces",
    "movable_goods": "bienes muebles",
    "financial_investments": "inversiones financieras",
    "collections": "colecciones",
    "savings": "ahorros",
    "checks": "cheques",
    "coupons": "cupones",
    "family_allowance": "asignación familiar",
    "rental_income": "ingreso por alquiler",
    "interest_dividends": "intereses, dividendos",
    "loans_rentals": "préstamos, alquileres",
    "dues_grants": "cuotas, subvenciones",
    "gifts": "regalos",
    "refunds": "reembolsos",
    "salary": "salario",
    "sales": "ventas",
    "transfer_bank": "transferencia",
    "cash": "efectivo",
    "credit_card": "tarjeta de crédito",
    "debit_card": "tarjeta de débito",
    "check": "cheque",
    "us_dollar": "dólar estadounidense",
    "euro": "euro",
    "british_pound": "libra esterlina",
    "japanese_yen": "yen japonés",
    "swiss_franc": "franco suizo",
    "canadian_dollar": "dólar canadiense",
    "australian_dollar": "dólar australiano",
    "new_zealand_dollar": "dólar neozelandés",
    "swedish_krona": "corona sueca",
    "norwegian_krone": "corona noruega",
    "danish_krone": "corona danesa",
    "singapore_dollar": "dólar singapurense",
    "hong_kong_dollar": "dólar de Hong Kong",
    "chinese_yuan": "yuan chino",
    "russian_ruble": "rublo ruso",
    "indian_rupee": "rupia india",
    "indonesian_rupiah": "rupia indonesia",
    "brazilian_real": "real brasileño",
    "mexican_peso": "peso mexicano",
    "argentine_peso": "peso argentino",
    "pastry_shop": "pastelería",
    "pub": "pub",
    transportation: "transportation",
    investments: "investments",
    food_beverages: "food & beverages",
    computer_communication: "computer communication",
}
</script>
