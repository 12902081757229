<script>
export default {
    sign_in_with: 'Sign in with',
    or_sign_in_with_credentials: 'Or sign in with credentials',
    sign_up_with: 'Sign up with',
    generic_error: 'An error occurred. Please try again later.',
    i_agree_with_the: ' I agree with the ',
    we_have_sent_you_an_email: ' We have sent you an email :-)',
    email: 'Email',
    password: 'Password',
    lost_your_password: 'Lost your password?',
    recovery_here: 'recovery here',
    sign_in: 'Sign In',
    forgot_password: 'Forgot password?',
    create_new_account: 'Create new account',
    terms_of_service: 'Terms of Service',
    link_privacy: 'Privacy Policy',
    term_conditions_text: 'By registering or logging in with one of the aforementioned services, you consent to our {link_terms} and acknowledge our {link_privacy}, which outlines how we handle your personal data.',
    about_us: 'About Us',
    blog: 'Blog',
    mit_license: 'AGPL-3.0 license',
    docs: 'Docs',
    loading: 'Loading',
    dashboard: 'Dashboard',
    add_new_entry: 'Add new entry',
    add_new_model: 'Add new model',
    my_entries: 'My entries',
    search_entries: 'Search entries',
    payee_list: 'Payee list',
    planned_entries: 'Planned entries',
    budgets: 'Budgets',
    settings: 'Settings',
    logout: 'Logout',
    expenses: 'Expenses',
    incoming: 'Incoming',
    transfer: 'Transfer',
    debit: 'Debit',
    choose_one_of_currently_tags: 'Choose one of the current tags',
    choose_a_wallet_to_transfer_to: 'Choose a wallet to transfer to',
    choose_wallet_account: 'Choose wallet account',
    choose_wallet_to_transfer: 'Choose a wallet to transfer to',
    payment_confirg: 'Payment confirmation',
    choose_an_option: 'Choose an option',
    choose_a_model: 'Choose a model',
    out_of_wallet: 'Out of wallet',
    insert_new_tag_name: 'Insert new TAG name',
    add_here_your_note: 'Add your note here',
    insert_new_entry: 'Insert new entry',
    show_planned_entries: 'Show planned entries',
    show_more: 'Show more',
    restore: 'Restore',
    reasume_of_all_entries: 'Resume of all entries',
    list_of_all_payees: 'List of all payees',
    forget_debit: 'Forget debit',
    date_start: 'Date start',
    debit_name: 'Debit name',
    date_end: 'Date end',
    choose_frequency: 'Choose a frequency',
    choose_method: 'Choose a method',
    insert_new_planned_entry: 'Insert new planned entry',
    create_new_budget: 'Create new budget',
    budget_name: 'Budget name',
    period: 'Period',
    budget: 'Budget',
    account: 'Account',
    category: 'Category',
    label: 'Label',
    labels: 'Labels',
    entry_type: 'Entry type',
    note: 'Note',
    enable_email_notification: 'Enable email notification',
    save_budget: 'Save budget',
    add_new_wallet: 'Add new wallet',
    show_archived: 'Show archived',
    category_settings: 'Category settings',
    add_new_category: 'Add new category',
    category_name: 'Category name',
    save_changes: 'Save changes',
    label_color: 'Label color',
    archive_label: 'Archive label',
    add_model_entry: 'Add new model entry',
    model_settings: 'Model settings',
    currency_settings: 'Currency settings',
    wallets: 'Wallets',
    models: 'Models',
    categories: 'Categories',
    currency: 'Currency',
    profile: 'Profile',
    assistance: 'Assistance',
    my_entry: 'My entry',
    planned: 'Planned entry',
    add: 'Add',
    model: 'Model',
    wallet: 'Wallet',
    payee: 'Payee',
    frequency: 'Frequency',
    method: 'Method',
    amount: 'Amount',
    date: 'Date',
    tag: 'Tag',
    tags: 'Tags',
    save: 'Save',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    delete: 'Delete',
    edit: 'Edit',
    show: 'Show',
    hide: 'Hide',
    archive: 'Archive',
    unarchive: 'Unarchive',
    search: 'Search',
    show_details: 'Show details',
    bounce_rate: 'Bounce rate',
    session_duration: 'Session duration',
    amount_before: 'Amount before',
    send_a_request: 'Send a request',
    set_your_default_currency: 'Set your default currency',
    change_order: 'Change order',
    add_new_workspace: 'Add new workspace',
    set_up_your_workspaces: 'Set up your workspaces',
    choose_parent_category: 'Choose a parent category',
    exclude_from_stats: 'Exclude from stats',
    choose_currency: 'Choose a currency',
    choose_wallet_type: 'Choose a wallet type',
    payment_deadline: 'Payment deadline',
    credit_card_installment: 'Credit card installment',
    wallet_colo: 'Wallet color',
    wallet_settings: 'Wallet settings',
    workspace_settings: 'Workspace settings',
    default_currency: 'Default currency',
    default_payment_type: 'Default payment type',
    share_workspace_with: 'Share workspace with',
    share: 'Share',
    remove: 'remove',
    workspace_shared_with: 'Workspace shared with',
    choose_a_category: 'Choose a category',
    payment_confirm: 'Payment confirmation',
    my_planned: 'Planned',
    my_health: 'Health',
    workspace: 'Workspace',
    entry_saved: 'Entry saved successfully',
    could_not_log_in: 'I can not log in',
    send_my_password: 'Send my password',
    recovery_your_password: 'Recovery your password',
    name: 'Name',
    confirm_password: 'Confirm password',
    privacy_policy: 'Privacy & Policy',
    create_account: 'Create new account',
    click_here: 'Click here',
    save_template: 'Save template',
    set_new_password: 'Set new password',
    account_created: 'Account created successfully, check your email to confirm and activate your account',
    search_by_text: 'Search by text',
    is_planned: 'Is planned',
    no: 'NO',
    yes: 'YES',
    date_interval: 'Date interval',
    type_of_transaction: 'Type of transaction',
    choose_period: 'Choose a period',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly',
    one_shot: 'One-shot',
    recursive: 'Recursive',
    or_nsert_new_tag_name: 'Or insert new TAG name',
    no_budgets_found: 'No budgets found',
    list_of_entries_of: 'List of entries of',
    no_payees_found: 'No payees found',
    list_of_all_budgets: 'List of all budgets',
    list_of_all_planned_entries: 'List of all planned entries',
    set_your_default_currecny: 'Set your default currency',
    update: 'Update',
    stats: 'Stats',
    payment_types: 'Payment types',
    create_new_debit: 'Create new debit',
    closing_account_statement: 'Closing account statement',
    months: {
        jan: 'Jan',
        january: 'January',
        feb: 'Feb',
        february: 'February',
        mar: 'Mar',
        march: 'March',
        apr: 'Apr',
        april: 'April',
        may: 'May',
        jun: 'Jun',
        june: 'June',
        jul: 'Jul',
        july: 'July',
        aug: 'Aug',
        august: 'August',
        sep: 'Sep',
        september: 'September',
        oct: 'Oct',
        october: 'October',
        nov: 'Nov',
        november: 'November',
        dec: 'Dec',
        december: 'December',
    },
    account_payment: 'Account payment',
    start_date: 'Start date',
    end_date: 'End date',
    show_entries: 'Show entries',
    wallet_name: 'Wallet name',
    balance_wallet: 'Balance wallet',
    wallet_type: 'Wallet type',
    next_execution_time: 'Next execution day',
    end_time: 'End day',
    list_of_voices: 'List of voices',
    no_voices_found: 'No voices found',
};

</script>
