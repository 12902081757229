<script>
export default {
    wrong_password: `The credentials you entered are not valid.`,
    email_not_verified: `You haven't verified your email yet. If you haven't received it, click here to resend.`,
    model_saved: `Model saved successfully.`,
    entry_Saved: `Entry saved successfully.`,
    generic_error: `An error occurred. Please try again later.`,
    validation: {
        choose_wallet: 'Please choose a wallet account',
        choose_category: 'Please choose a category',
        insert_amount: 'Please insert amount value',
        choose_wallet_transfer: 'Please choose a different wallet to transfer',
        insert_payee: 'Please insert a payee name',
    },
    login: {
        not_verified_email: `You haven't verified your email yet. If you haven't received it, click here to resend.`,
        not_valid_password: `The credentials you entered are not valid.`,
    },
    wallet: {
        are_you_sure: 'Are you shure do you want archive these wallet ?',
        archived: 'Wallet archived',
        restored: 'Wallet restored',
        saved: 'Wallet saved',
        wrong_Wallet: 'Please choose a right wallet type',
        wallet_name: 'Please insert a wallet name',
        wallet_currency: 'Please choose a wallet currency',
        invoice_date: 'Please choose a invoice date',
        closing_date: 'Please choose a closing date',
        payment_account: 'Please choose a payment account',
        installment: 'Please choose a installment value',
    },
    workspace: {
        updated: 'Workspace updated',
        added: 'Workspace added',
        user_not_fount: 'User not found'
    },
    search: {
        no_results: 'No results found',
        please_set_date: 'Please choose a date start and end',
    },
}
</script>
