<script>
export default {
    wrong_password: `La password inserita non è corretta.`,
    email_not_verified: `L'indirizzo email non è ancora verificato. Se non hai ricevuto l'email, clicca qui per inviarla nuovamente.`,
    model_saved: `Modello salvato con successo.`,
    entry_Saved: `Voce salvata con successo.`,
    generic_error: `Si è verificato un errore. Per favore riprova più tardi.`,
    validation: {
        choose_Wallet: 'Per favore scegli un account del portafoglio',
        choose_Category: 'Per favore scegli una categoria',
        insert_amount: 'Per favore inserisci un valore per l\'importo',
        choose_Wallet_transfer: 'Per favore scegli un portafoglio diverso per il trasferimento',
        insert_payee: 'Per favore inserisci un nome del beneficiario',
    },
    login: {
        not_verified_email: `L'indirizzo email non è ancora verificato. Se non hai ricevuto l'email, clicca qui per inviarla nuovamente.`,
        not_valid_password: `La password inserita non è corretta.`,
    },
    wallet: {
        are_you_sure: 'Sei sicuro di voler archiviare questo portafoglio?',
        archived: 'Portafoglio archiviato',
        restored: 'Portafoglio ripristinato',
        saved: 'Portafoglio salvato',
        wrong_Wallet: 'Per favore scegli un tipo di portafoglio corretto',
        wallet_name: 'Per favore inserisci un nome per il portafoglio',
        wallet_currency: 'Per favore scegli una valuta per il portafoglio',
        invoice_date: 'Per favore scegli una data di fatturazione',
        closing_date: 'Per favore scegli una data di chiusura',
        payment_account: 'Per favore scegli un conto di pagamento',
        installment: 'Per favore scegli un valore di rata',
    },
    workspace: {
        updated: 'Spazio di lavoro aggiornato',
        added: 'Spazio di lavoro aggiunto',
        user_not_fount: 'Utente non trovato'
    },
    search: {
        no_results: 'Nessun risultato trovato',
        please_set_date: 'Scegli una data di inizio e fine',
    },
}
</script>