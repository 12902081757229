<script>
export default {
    about_us: {
        version: `Budget Control of Mlabfacotry version `,
        personal_finance: `<b>Personal finance manager</b><p>Budget Control by Mlabfactory is a personal finance management tool that helps you track your spending, so you can stay in control and achieve your goals.</p>`,
        information: `<b>Budget Control information</b><p>Mlabfactory is a professional developer based in Italy, Milan has been operating and developing applications since 2008. Find out more about the team</p><a href="http://mlabfactory.it">Mlabfacotry.it</a>`,
        visit_our_website: 'Visit our website',
    },
    assistance: {
        write_question: `<p>Write here your question</p>`,
        thanks: `Thank you for your request. We will get back to you as soon as possible.`,
    },
    profile: {
        policy: `<p>Registrandoti o connettendoti con uno dei suddetti servizi,acconsenti ai nostri <a class="font-bold text-decoration-line"
                    href="https://www.budgetcontrol.cloud/terms/">Termini di Servizio</a> e
                riconosci la nostra <a class="font-bold text-decoration-line"
                  href="https://www.budgetcontrol.cloud/privacy/">Informativa sulla Privacy</a>,
                che descrive come gestiamo i tuoi dati personali.</p>`
    },
    settings: {
        manage_your_workspace: 'Manage your workspaces.',
        manage_wallets: 'Manage wallets, change icons, color.',
        manage_entry: 'Manage your entry categories.',
        customize_entry: 'Customize entry labels.',
        configure_model: 'Configure entry models.',
        set_currency: 'Set your preferred currency.',
        edit_profile: 'Edit your user profile.',
        learn: 'Learn more about our team.',
        write_us: 'Write us for assistance.',
    },
    install_app: 'Hey!</b> Install our app to get the best experience.',
}
</script>